export default {
    LANGUAGE:{
        NAME:"Portugues"
    },
    SISTEMA:{
        HISTORICO: "Histórico",
        APROVAR: "Aprovar",
        CUSTOFABRICA: "ATs Custo Fábrica",
        ACOMPANHAMENNTO:"Acompanhamento",
        SALVOSUCESSO:"Salvo com sucesso",
        ENVIAR: "Enviar",
        EDITAR: "Editar",
        INCLUIR: "Incluir",
        LISTAR: "Listar",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        CONFIRMAR: "Confirmar",   
        CODIGO: "Código",     
        CLIENTE: "Cliente",
        UNIDADE: "Unidade",
        FUNCIONARIO: "Funcionário",        
        SETOR: "Setor",        
        FORNECEDOR: "Fornecedor",        
        SOMENTELEITURA: "Somente leitura",
        NOVO: "Novo",        
        GUIA: "Guia", 
        SELECIONEDATAHORA: "Selecione a data e hora",        
        ATENCAO: "Atenção!",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual", 
        PROCURAR: "Procurar",
        ALTERAR: "Alterar",            
        ERROSALVAR: "Erro ao salvar: ",
        SELECMODEL: "Selecione o Modelo",
        LISTARTODOS: "Listar todos", 
        FECHAR: "Fechar",        
        SAIR: "Sair",                                       
        JANEIRO: "Janeiro",
        FEVEREIRO: "Fevereiro",
        MARCO: "Março",
        ABRIL: "Abril",
        MAIO: "Maio",
        JUNHO: "Junho",
        JULHO: "Julho",
        AGOSTO: "Agosto",
        SETEMBRO: "Setembro",
        OUTUBRO: "Outubro",
        NOVEMBRO: "Novembro",
        DEZEMBRO: "Dezembro",
        NOME: "Nome",    
        ID: "Id",
        MANUTENCAO: "Cadastro",
        ATENDIMENTO: "Atendimento",
        VENDAS: "Vendas",
        POSVENDA: "Pós Venda",
        AGENDA: "Agenda",
        DESC: "Descrição",
        DATA: "Data",
        DESATIVADO: "Desativado",              
        CAMPOSOBRIGATORIOS: "Preencha todas os campos!",   
        NENHUM: "Nenhum",
        OBSERVACAO: "Observação",                     
        STATUS: "Status",
        RELATORIO: "Relatórios",
        SIM: "Sim",
        NAO: "Não",
        VALOR: "Valor",
        PDF: "PDF",
        EXPORTAR: "Exportar",
        TIPO: "Tipo",
        EXCLUIR: "Excluir",
        REMOVER: "Remover",
        OBSERVACAOOBRIGATORIA: "Campo observação deve ter no mínimo 20 caracteres",
        EMPRESA: "Empresa"
    },
    MODALISTARULTIMASOBSERVACOES:{
        ULTIMASOBSERVACOES:"Últimas Observações",

    },         
    CUSTOFABRICA:{
        
        APROVAR: "Aprovar/Reprovar Solicitação",
        APROVARSOLICITACAO:"Aprovar",
        REPROVAR: "Reprovar Solicitação",        
    },  
    RELATORIO:{
        SISTEMA: "Sistema",
        TIPO: "Tipo",
        USUARIO: "Usuário",
        MANUTENCAO: "Manutenção"
    },

    FILTROS:{
        EMPRESA: "Empresa",
        FILTRO: "Filtros",        
        PERIODO: "Período",
        FILTRAR: "Filtrar por",
        DATACRIACAO: "Data criação",
        STATUS: "Status",  
        NOME: "Nome",      
        CODIGO: "Código",
    },    
    
    SOBRE: {
        APRESENTACAO: "Sistema de gerenciamento de móveis planejados - GERENCIAL",
        LICENCIADOPARA: "Este sistema está licenciado para:",
        VERSAO: "versão" 
     },
     HISTORICO: {
        FECHAR: "Fechar",
        TITULO: "Histórico de alterações",
        INFORMACOES: "Informações",
    },         
    MANUTENCAOLISTAR: {
        PESSOAS: "Pessoas",
        CLIENTES: "Clientes",             
        FUNCIONARIO: "Funcionários",
        NIVELACESSO: "Níveis de acesso",
        EMPRESA: "Empresas",
        FORNECEDOR: "Fornecedores",
        SETORES: "Setores",
        DEPARTAMENTOS: "Departamentos",
        MOTIVOS: "Motivos",
        OUTROS: "Outros",
        UNIDADES: "Unidades",
        TIPOAGENDAMENTO: "Agendas",
        FERIADO: "Feriados",
        CONDPAGAMENTO: "Condições de pagamento",
        TIPOSANEXOS: "Tipos de Anexos",
        ESPECIEPAGAMENTO: "Espécie de pagamento",
        PRAZOS: "Prazos",
        CUSTOS: "Custos",
    },  
    ANEXOS:{
        ARRASTEARQUIVOS: "Arraste os arquivos aqui ou clique para selecioná-los",
        REMOVERANEXO: "Remover anexo",
        ANEXO: "Anexo",
        EXCLUIRANEXO: "Exclusão de anexo",
        CONFIRMAEXCLUSAO: "Confirma a exclusão do anexo: <b>",
        APENASPDF: "Limite de até 50MB",
        APENASFOTOS: "Apenas arquivos PDF",
    },    
    
    MODALUSUARIO: {
        TITULO: "Configuração do Usuário",        
        ALTERARSENHA: "Alterar senha",
        LINKSUTEIS: "Links úteis:",
        SUPORTEREMOTO: "Suporte remoto",
        VIDEOSTREINAMENTO: "Vídeos de Treinamento",
        AGENTEIMPRESSAO: "Agente de Impressão",
        ALTERARSENHAMSG: "Tem certeza que deseja alterar a senha do seu usuário?",        

    },

  
    UNIDADELISTA: {
        UNIDADES: "Unidades",
    },
    UNIDADECADASTRO: {
        SALVANDOUNIDADE: "A unidade está sendo salva, este processo pode levar alguns minutos.",        
        HORAPADRAO: "Hora Padrão de Retorno",
        FIMEXP: "Hora de Fim do Expediente",
        MODELORES: "Modelo de Resultado",
        MODELOSEL: "Selecione o Modelo",        
        CODINTEG: "Códigos integrações",                          
        UNIDADE: "Unidade",
        CONFIGNF: "Configurações",
        CAMPOSNF: "Campo",
        VALORESNF: "Valor",
        ADDCONFIG: "Adicionar Configuração"
    },   
    
    BUSCACEP: {
        CEP: "CEP",
        CEPMESSAGEM: "CEP não encontrado",
        ENDERECO: "Endereço",
        BAIRRO: "Bairro",
        NUMERO: "Número",
        COMPLEMENTO: "Complemento"
    },
    BUSCACIDADE: {
        CIDADE: "Cidade",
        NENHUMACIDADE: "Nenhuma cidade selecionada",
    },        
    NIVELDEACESSO: {
        NIVEL: "Níveis de acesso",
    },
    NIVELDEACESSOCADASTRO: {
        SALVANDONIVEL: "O nível está sendo salvo, este processo pode levar alguns segundos.",                
        ADM: "Permitir ver outras unidades?",                
        REGRAS: "Regras",        
        NIVEL: "Nível de Acesso",
        UNIDADESDECADASTRO: "Unidades",
    },   
    FUNCIONARIOLISTA: {
        PROCURARNOME: "Procurar por nome",
        NIVELACESSO: "Nível de Acesso",
        USUARIO: "Usuário",
        EMAIL: "E-mail",                
        FUNCIONARIO: "Funcionários",
        DESATIVADO: "Funcionário Desativado"
    },
    FUNCIONARIOCADASTRO: {
        UNIDADE: "Unidade",
        USUARIO: "Usuário",
        ALTERAR: "Alterar",
        SENHA: "Senha",
        CONFIRMARSENHA: "Confirmação de Senha",
        CPF: "CPF",
        FONE: "Fone",
        CELULAR: "Celular",
        NIVEL: "Nível",
        EMAIL: "Email",        
        ASSINATURA: "Assinatura Texto",
        FOTO: "Foto",
        CAPTURAR: "Clique para capturar pela webcam",
        ARQUIVO: "Escolher arquivo...",
        DESATIVADO: "Desativado",        
        ESCOLHERARQ: "Escolher Arquivo",
        MUDARSENHA: "Redefinir Senha",        
        FUNCIONARIO: "Funcionário",
        SENHASIGUAIS: "As senhas devem ser iguais",
        USUARIOLOGADO: "Não é possível alterar o usuário atualmente logado!",
        ALTERARUSUARIO: "Tem certeza que deseja alterar o login desse funcionário?",        
        CODIGOOPERADORA: "Código na Operadora",
    },   
    CLIENTESLISTA: {                
        SEXO: "Sexo",
        NASCIMENTO: "Nascimento",        
        EMAIL: "E-mail",
        RESPONSAVEL: "Responsável",
        DESATIVADO: "Desativado",                
        ESPECIE: "Espécie",
        RACA: "Raça",
        ARQUITETO: "Arquiteto"
    },
    CLIENTECADASTRO: {              
        MSGERROCPF : "CPF inválido",
        ARQUIVO: "Escolher arquivo...",
        PENDENCIA: "Sem pendências financeiras",        
        NOMESOCIAL: "Nome Social",        
        FONE: "Fone",
        CELULAR: "Celular",
        ENVIAMSG: "Envia mensagens SMS/WhatsApp",
        SEXO: "Sexo",
        SELECIONESEXO: "Selecione o sexo",
        FEM: "Feminino",
        MASC: "Masculino",
        INDT: "Indeterminado",
        NASCIMENTO: "Nascimento",
        IDADE: "Idade",
        CALCIDADE: "Calcular Idade em",
        ANOS: "Anos",
        MES: "Meses",
        DIA: "Dias",
        PESO: "Peso (kg)",
        ALTURA: "Altura (cm)",
        EMAIL: "Email",
        RG: "RG",
        CPF: "CPF",        
        PASSAPORTE: "Passaporte",
        OBSERVACOES: "Observações",
        USUARIO: "Usuário",                
        DESATIVADO: "Desativado",        
        LISTAR: "Listar",
        LISTARMENSAGEM:"Listar atendimentos do cliente",
        CRIAR: "Criar",
        CRIARMENSAGEM:"Criar novo atendimento",
        DOC: "Documentos",
        DOCPESSOAIS: "Documentos Pessoais",
        NENHUMDOC: "Nenhum documento",        
        INDICACAO: "Indicação",                                           
        NASCIMENTOINVALIDO: "Valor informado para a data de nascimento inválido!",
        IDADEINVALIDA: "Valor informado para a idade inválido!",
        EMAILMSG: "O e-mail ",
        EMUSO: " já está sendo usado pelo usuario do ",        
        CPFOBRG: "CPF ou passaporte obrigatório.",
        ENDOBRG: "Endereço obrigatório.",
        EMAILOBRG: "Email obrigatório.",
        CELOBRG: "Celular obrigatório.",        
        CPFUSO: "Este CPF já está em uso.",
        CODIGOEXTERNOEMUSO: "Este código externo ja está em uso",
        ERROSALVARCLIENTE: "Erro ao salvar cliente, verificando cpf: ",
        ERROCLIENTE: "Erro ao salvar cliente: ",
        ERROHIST: "Erro ao carregar histórico: ",  
        AGENDAMENTOS: "Próximos Agendamentos",
        ARQUITETO: "Arquiteto",
        
    },    
    
                 
    MODALNOTIFICACAO: {
        TITULO: "Nenhuma Notificação | 1 Notificação | {n} Notificações",
        FECHAR: "Fechar",
        APAGARTODAS: "Apagar Todas",
        APAGAR: "Apagar esta notificação"        
    },    
              
        
}